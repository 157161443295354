<template>
    <div class="login">
        <div class="login-user-img">
             <van-image fit="cover"
                class="img-box"
                :src="sysOfficeInfo.img">
                <template v-slot:loading>
                    <img :src="good" />
                </template>
                <template v-slot:error>
                  <img :src="good" />
                </template>
            </van-image>
        </div>
        <div class="login-user-name">
           {{sysOfficeInfo.name?sysOfficeInfo.name:'必订货'}}
        </div>
        <div class="login-form">
            <van-field v-model="formData.phone" pattern="[0-9]*" type='tel' clearable placeholder="输入手机号" />
            <van-field v-model="formData.code" placeholder="输入验证码">
                <van-button 
                    v-if="!statusGetCode" 
                    slot="button" 
                    @click="handleClickGetCode()" 
                    size="small">获取验证码</van-button>
                <van-button 
                    style="color:#999999"
                    v-else 
                    disabled
                    slot="button" 
                    size="small">{{time}}s</van-button>
            </van-field>
        </div>
        <div class="login-btn-box" @click="handleClickLogin">
            <div class="login-btn">登 录</div>
        </div>
    </div>
</template>
<script>
import './index.less'
import good from "@/assets/img/touxiang.png"
import {regPhone} from "@/libs/utils"
import { getCode , login} from "@/apis/api"
export default {
    data(){
        return{
            good:good,
            sysOfficeInfo:{
                id:localStorage.getItem('sysOfficeId'),
                name:localStorage.getItem('sysOfficeName'),
                img:localStorage.getItem('sysOfficeImg'),
            },
            formData:{
                phone:"",
                code:""
            },
            statusGetCode:false,
            time:60
        }
    },
    methods:{
        handleClickGetCode(is){
            if(regPhone(this.formData.phone)){
                let req = {
                    phone:this.formData.phone
                }
                getCode(req).then(res=>{
                    if(res.code === 0){
                        this.$toast("获取验证码成功")
                        this.statusGetCode = !this.statusGetCode;
                        this.handleChangeTime()
                    }else{
                        this.$toast(res.msg)
                    }
                })
            }else{
                this.$toast("手机号码格式错误，请重新填写")
            }
        },
        //开始倒计时
        handleChangeTime(){
            let timeOut = setInterval(()=>{
                if(this.time > 0){
                    this.time = this.time - 1 ;
                }else{
                    clearInterval(timeOut)
                    this.time = 60
                    this.statusGetCode = !this.statusGetCode;
                }
            },1000)
        },
        //进入订货页面
        handleClickLogin(){
            let str = this.formData.code;   
            let reg = new RegExp("^[0-9]*$"); 
            if(!regPhone(this.formData.phone)){
                this.$toast("请输入正确格式手机号码")
            }else if(str.length!=6 || !reg.test(str)){    
                this.$toast("请输入正确验证码"); 
            }else if(regPhone(this.formData.phone) && this.formData.code){
                let req = this.formData;
                login(req).then(res=>{
                    if(res.code === 0){
                        localStorage.setItem('customerId',res.res.customerId);
                        localStorage.setItem('customerName',res.res.customerName);
                        localStorage.setItem('customermasterId',res.res.customermasterId);
                        this.$toast("登录成功")
                        this.$router.push({
                            name:'index'
                        })
                    }else{
                        this.$toast(res.msg)
                    }
                })
            }
        }
    }
}
</script>